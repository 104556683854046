
const categories_data = [
    {
        id:"1",
        categorie_name:"Nature",
        is_Active:"True"
    },
    {
        id:"2",
        categorie_name:"ECommerce",
        is_Active:"False"
    },
    {
        id:"3",
        categorie_name:"Wildness",
        is_Active:"False"
        
    }
    ,
    {
        id:"4",
        categorie_name:"Technology",
        is_Active:"False"
    }
    ,{
        id:"5",
        categorie_name:"Agriculture",
        is_Active:"False"
    }
    ,
    {
        id:"6",
        categorie_name:"Travel",
        is_Active:"False"
    }
    ,{
        id:"7",
        categorie_name:"Parenting",
        is_Active:"False"
    }
    ,{
        id:"8",
        categorie_name:"Food recipies",
        is_Active:"False"
    }
    ,{
        id:"9",
        categorie_name:"Health care",
        is_Active:"False"
    }
    ,{
        id:"10",
        categorie_name:"Pop culture ",
        is_Active:"False"
    },{
        id:"11",
        categorie_name:"Entertainment",
        is_Active:"False"
    },{
        id:"12",
        categorie_name:"Fashion & beauty",
        is_Active:"False"
    }
]





export default categories_data