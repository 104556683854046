import image from "../assets/python.jpg";

const course_data = [
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
	{
		image: image,
		name: "Python Course",
		author: "Mr.Colleen van Lent",
		price: 2500,
		rating: 4.5,
		prevPrice: 4000,
	},
];

export default course_data;
