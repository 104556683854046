import image from "../assets/acorns.png";
const Tech_data = [
    {
        image:image,
        title:"AI",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"blockchain",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"Computer",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"blockchain",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"AI",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"blockchain",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"Ruby",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"blockchain",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"Crypto",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"blockchain",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"Laptop an Gagets",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"bitcoin",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"Laptop an Gagets",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"bitcoin",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"Pi",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"bitcoin",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"Laptop an Gagets",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"bitcoin",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"Pi",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"bitcoin",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"GTA",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"game",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"GTA",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"game",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"IGI",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"game",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"GTA",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"game",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
    {
        image:image,
        title:"IGI",
        desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        genre:"game",
        author: "Arlene McCoy",
        authorImage: "https://via.placeholder.com/32?text=A",
        duration: "10 Min",
    },
]
export default Tech_data;