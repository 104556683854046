import image from "../assets/college_image.png";

const college_data = [
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
	{
		image: image,
		name: "Master of Business Administration.",
		description: "Application due December 14, 2024",
	},
];

export default college_data;
