import image from "../assets/acorns.png";

const saving_application_data = [
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
	{
		image: image,
		name: "Acorns",
		description:
			"Acorns is a micro-investing app that rounds up your daily purchases and invests the spare change into diversified portfolios.",
	},
];

export default saving_application_data;
